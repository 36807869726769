import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"

export default function Favorites() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all writing
        </div>
      </Link>

      <div className="text-2xl font-bold mb-2">
        Rooting STEM education in liberal arts principles
      </div>

      <div className="text-gray-600 mb-6">
        7/23/21
      </div>

      <div className="space-y-4">
        <p>
          In the past few years and decades, there has been a concerted effort among universities to ramp up STEM education—but these initiatives have often come at the expense of traditional liberal arts classes at the other end of the academic spectrum. For more STEM-minded schools, such a strategy might seem perfectly logical. However, I believe that schools that aim to provide their students with holistic preparation for the world should not just maintain, but increase their current arts and humanities requirements. Universities that claim to shape students into well-rounded problem-solvers should provide a STEM education that not only includes technical classes, but also liberal arts classes along with an integration of humanities concepts into many technical classes. For students aspiring to change the world through STEM, a good technical foundation is important, but it is necessary that such a foundation is complemented with a deep understanding that technology is not always the answer to our problems, and even when it is, its benefits to humankind depend entirely on the quality of its real-world implementation.
        </p>
        <p>
          First, it is critical that STEM students take classes that engage in a variety of liberal arts topics outside of the context of science and technology. Such classes provide a fundamental understanding of the world crucial for all disciplines and build critical thinking skills—but not just the career-boosting ones that companies condition students to seek. Liberal arts classes enable students to understand the principles of our world and learn how to examine the systems and social constructs that shape our everyday lives. Without a deep understanding of how our world works and why it came to be, it can be very difficult to break from the status quo and imagine a better future: making the world a better place first requires careful identification and examination of the problems that we face. Liberal arts classes teach students how to solve a problem in more than one way, but I believe they also teach students how to define a problem in more than one way. Take climate change, for instance. A solely science-minded individual might correctly observe that the process of extracting and burning fossil fuels could be engineered to be less environmentally harmful, but with a wider analysis, one can argue that the larger problem is a political one: a fossil fuel industry that lies to consumers and lobbies against any meaningful regulations.
        </p>
        <p>
          In addition to more liberal arts classes, we need to increase the integration of liberal arts analysis into STEM classes. A common pitfall among scientists and engineers is the belief that technology is an inherently neutral tool. However, this is far from the truth—finished technology products are not simply compositions of numbers or chemicals. Rather, they are human artifacts that reflect the thoughts, beliefs, and biases of the people who created them. Therefore, it is crucial that creators of technology are informed about best practices of responsible engineering and are aware of their own assumptions and limitations. A similar pitfall is the belief that science is some sort of empirical utopia where decisions can’t negatively impact society and thus real-world context and implications don’t need to be deeply considered. I myself have often heard this sentiment from many of my own STEM-minded classmates—that they like science because it doesn’t deal with “messy politics” or because they don’t have to consider any complicated social or economic issues. That may be true for basic research, but for applied research and corporate product development, it is ultimately the real-world implementation of technology, not its inherent scientific elegance, that determines its impact on humankind. Far too often, as we unfortunately keep experiencing in our modern age, scientists and engineers simply build things without proper ethical analysis and then completely wash their hands of any societal consequences. (A certain corporate slogan about breaking things whilst moving quickly comes to mind, for instance.) This will keep happening if we continue to let STEM education allow students to think that they can treat technology as a playground divorced from any real-world circumstances. We must integrate liberal arts analysis into STEM curriculum in order to teach students how to engineer with caution and innovate responsibly.
        </p>
        <p>
          A commonly voiced opinion among STEM enthusiasts is that this second component of liberal arts education is sufficient on its own—that it is not necessary for STEM students to take seemingly extraneous liberal arts classes as long as they learn the humanities content directly related to their science and engineering careers. Supporters of this opinion argue that students don’t need to learn anything beyond what’s immediately relevant to their chosen fields—that humanities-only classes are just distractions that result in STEM students becoming mediocre scientists and engineers. But I believe that it truly is necessary to study liberal arts concepts both inside and outside the STEM classroom. When social and ethical topics are introduced to students solely in the context of science and technology, “it becomes difficult to discuss bigger picture topics not immediately connected to” technical subjects, Jessica Dai writes in her essay "<a href="https://www.theindy.org/2235" className="underline">The Paradox of Socially Responsible Computing</a>" (Dai 2-3). She explains that exclusively teaching content that centers technology can train students to only think within the boundaries of technological fixes, instead of examining the larger social or economic aspects of a problem and also considering non-technical solutions (Dai 3). I strongly agree with Dai; we need students to begin thinking not about the world in the context of technology, but rather technology in the context of the world. Returning to the example of climate change, it is the liberal arts classes that enable students to holistically consider the problem, not just the engineering classes that discuss how to develop and deploy green technology.
        </p>
        <p>
          Without both STEM-related humanities curriculum and liberal arts-only classes, STEM students will be unprepared to think critically about the world and consider the purpose and impacts of the technology they build. If we want to avoid a future filled with avoidable technology disasters, we must act now to train students to be flexible and think holistically.
        </p>
      </div>
      
    </Layout>
  )
}